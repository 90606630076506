<!--
 * @Author: wang peng
 * @Description: 数据统计 平台统计 商户充电统计列表
-->
<template>
  <scroll-layout class="merchantStatistics-list">
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        v-if="topTipStatus"
        :description="topTip"
        @click="topTipStatus = false"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParam"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div style="margin-bottom: 20px">
      <statistics-chart
        :title="'充电趋势'"
        :page="'platform'"
        :isUpdate="isUpdate"
        :searchParams="searchParam"></statistics-chart>
    </div>
    <div class="tags">
      报表统计口径说明
      <span @click="tagClick()">?</span>
    </div>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'data:merchan:charge:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <ykc-operation-dialog
        size="large"
        title="统计口径说明"
        :show.sync="operationStatus"
        :show-footer="false"
        confirm-btn-txt="同意">
        <div slot="content">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            :autosize="{ minRows: 2 }"
            v-model="textarea2"></el-input>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import statisticsChart from '../../components/dataStatisticsChart.vue';

  import { getLastTime, getLastYear, offlineExport } from '@/utils';
  import { operatorData, dataStatisticsServe } from '@/service/apis';

  export default {
    name: 'merchanChargingStatisticsList',
    components: {
      statisticsChart,
    },
    data() {
      return {
        topTip:
          '温馨提示：由于统计数据量过大，默认显示近7日统计数据，需要查看其他数据请使用查询功能。',
        tradeNo: null,
        isUpdate: false,
        topTipStatus: true,
        operationStatus: false,
        userOperatorList: [], // 归属商户组件使用数据源
        searchParam: {
          date: {},
          startTime: '',
          endTime: '',
          dateType: '1',
          operatorId: [],
        },
        tableTitle: '明细列表', // 列表表格
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          {
            label: '归属商户',
            prop: 'operatorName',
            fixed: true,
            width: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span
                    style="color:blue; cursor: pointer;"
                    onClick={() => this.handleRowClick(row)}>
                    {row.operatorName}
                  </span>
                );
              },
            },
          },
          { label: '电量(度)', prop: 'degree', width: '200px' },
          { label: '充电时长(h)', prop: 'chargeHours', minWidth: '150px' },
          { label: '充电次数', prop: 'chargeTimes', minWidth: '150px' },
          { label: '电费(元)', prop: 'chargeAmount', minWidth: '150px' },
          { label: '服务费(元)', prop: 'serviceAmount', minWidth: '150px' },
          { label: '订单总金额(元)', prop: 'orderAmount', minWidth: '160px' },
          {
            label: '用户实付合计(元)',
            prop: 'amountAfterDiscount',
            minWidth: '150px',
          },
          {
            label: '引流抽成费用(元)',
            prop: 'commissionAmount',
            minWidth: '150px',
          },
          {
            label: '商户实收合计(元)',
            prop: 'actualIncome',
            minWidth: '150px',
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        textarea2: `电量(度)：所有历史订单电量汇总
充电时长(h)：所有历史订单充电时长汇总
充电次数：订单总数量
电费(元)：所有历史订单原价电费
服务费(元)：所有历史订单原价服务费
订单总金额(元)：所有历史订单原价总金额
用户实付合计(元)：订单总金额- 平台卡券优惠- 平台活动优惠-商户卡券优惠-商户活动优惠-会员折扣优惠
引流抽成费用(元)：所有历史订单引流抽成总金额
商户实收合计(元)：订单总金额-商户卡券优惠-商户活动优惠-引流抽成费用`,
      };
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.startTime = startDate;
      this.searchParam.endTime = endDate;

      this.requestUserOperatorList();
      this.requestList();
    },
    computed: {
      searchData() {
        return [
          {
            isDateType: true,
            label: '时间维度',
            key: 'date',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
          {
            multiple: true,
            label: '商户名称',
            comName: 'YkcDropdown',
            key: 'operatorId',
            placeholder: '请选择商户名称',
            data: this.userOperatorList,
            // configSet: {
            //   label: 'name',
            //   value: 'operatorId',
            // },
          },
        ];
      },
    },
    methods: {
      /**
       * 获取归属商户数据
       */
      requestUserOperatorList() {
        operatorData
          .userOperatorList({})
          .then(res => {
            console.log('获取归属商户数据+++++', res);
            res.map(item => {
              return this.userOperatorList.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo);
        const searchParams = (({ startTime, endTime, dateType, operatorId }) => ({
          startTime,
          endTime,
          dateType,
          operatorId,
        }))(this.searchParam);
        const reqParams = {
          ...pageInfos,
          ...searchParams,
        };
        dataStatisticsServe
          .detailPageList(reqParams)
          .then(res => {
            this.isUpdate = false;
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 跳转商户
       */
      handleRowClick(row) {
        let url = '';
        let type = '';

        if (row.operatorType === '1') {
          url = '/merchantCenter/merchantPlatform/Detail';
          type = 'platForm';
        } else if (row.operatorType === '2') {
          url = '/merchantCenter/merchantPlatform/Detail';
          type = 'union';
        }

        this.$router.push({
          path: url,
          query: { type, id: row.operatorId },
        });
      },
      /**
       * 统计说明
       */
      tagClick() {
        this.operationStatus = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'OPERATOR_STATISTICS_EXPORT',
          jsonNode: {
            ...this.searchParam,
          },
        });
      },
      /**
       * 切换日月
       */
      handleChange(obj) {
        console.log('obj===', obj);
        const { select, event } = obj;

        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(3, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = startDateStr;
          this.searchParam.endTime = endDateStr;
          this.searchParam.dateType = '2';

          // console.log('this.searchForm切到月', this.searchParam);
        } else if (event === 'select' && select === 'daterange') {
          // 切换到日
          const startDateStr = getLastTime('yyyy-MM-dd', 7);
          const endDateStr = getLastTime('yyyy-MM-dd', 1);

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = startDateStr;
          this.searchParam.endTime = endDateStr;
          this.searchParam.dateType = '1';

          // console.log('this.searchForm切到日', this.searchParam);
        }
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        const searchFormData = { ...searchData };

        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          const [startDate, endDate] = searchData.date;
          searchFormData.startTime = startDate;
          searchFormData.endTime = endDate;
        }

        this.searchParam = searchFormData;
        this.isUpdate = true;
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);
        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.startTime = startDate;
        this.searchParam.endTime = endDate;
        this.searchParam.dateType = '1';
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .merchantStatistics-list {
    .tags {
      width: 160px !important;
      min-width: 0 !important;
      display: flex;
      align-items: center;
      font-size: 12px;
      position: relative;
      top: 35px;
      left: 85px;
      > span {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 8px;
        font-size: 10px;
        line-height: 16px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        background: blue;
      }
    }

    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }
</style>

<!--
 * @Author: wang peng
 * @Date: 2023-2-28
 * @Description: 图表组件
-->
<template>
  <div class="echarts-detail">
    <ykc-detail :titles="[title]" :tips="false" :isLine="false">
      <div slot="cvHead-rt">
        <ykc-checkbox-button
          type="button"
          :data="dataBtn"
          :configSet="props"
          v-model="selectCheckbox"
          @change="checkboxChange"></ykc-checkbox-button>
      </div>
      <div slot="cvBody">
        <div class="echarts-line">
          <div class="row">
            <ykc-charts-line
              :isData="isData"
              :chartOption="chartOption1"
              :isShowLoading="isShowLoading"></ykc-charts-line>
          </div>
        </div>
      </div>
    </ykc-detail>
  </div>
</template>

<script>
  import YkcChartsLine from './ykcEcharts/YkcChartsLine.vue';

  import { dataStatisticsServe } from '@/service/apis';
  import { chartlcm, YmaxvalAndinterval, getNextBiggestNumber } from '@/utils';

  export default {
    name: 'shareChart',
    components: { YkcChartsLine },
    props: {
      isUpdate: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
      title: {
        type: String,
        default: '',
      },
      page: {
        type: String,
        default: 'platform',
      },
      searchParams: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        yName: [],
        selectCheckbox: [0, 1], // 组件使用数据源
        isData: false, // 图表1是否有数据
        isShowLoading: false,
        props: {
          label: 'label',
          value: 'value',
        },
        chartOption: {}, // 图表的配置
        chartOption1: {}, // 图表1的配置
        dataBtn: [
          {
            value: 0,
            label: '电量(度)',
          },
          {
            value: 1,
            label: '订单总金额(元)',
          },
          {
            value: 2,
            label: '引流抽成费用(元)',
          },
          {
            value: 3,
            label: '用户实付合计(元)',
          },
          {
            value: 4,
            label: '商户实收(元)',
          },
        ],
      };
    },
    watch: {
      isUpdate: {
        handler(val) {
          if (val) {
            this.getCharts();
          }
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      this.getCharts();
    },
    methods: {
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.selectCheckbox = e;
        this.getCharts();
      },
      /**
       * 获取图表数据
       */
      getCharts() {
        dataStatisticsServe
          .chargingTrendList({ ...this.searchParams })
          .then(res => {
            console.log('图表数据+++++', res);
            if (res && res.length > 0) {
              this.isData = true;
              const chargedPower = []; // 电量
              const chargedAmount = []; // 订单总金额
              const commissionAmount = []; // 引流抽成费用
              const chargedAmountAfterDiscount = []; // 用户实付合计
              const operatorActualIncome = []; // 商户实收
              const platformActualIncome = []; // 平台实收

              // 处理实时数据
              res.forEach(item => {
                item.chargedPower = Number(item.chargedPower).toFixed(2);

                chargedPower.push({ value: [item.date, item.chargedPower] }); // 保存电量
                chargedAmount.push({ value: [item.date, item.chargedAmount] }); // 保存订单总金额
                commissionAmount.push({ value: [item.date, item.commissionAmount] }); // 保存订单总金额
                chargedAmountAfterDiscount.push({
                  value: [item.date, item.chargedAmountAfterDiscount],
                }); // 用户实付合计
                operatorActualIncome.push({ value: [item.date, item.operatorActualIncome] }); // 保存订单总金额
                platformActualIncome.push({ value: [item.date, item.platformActualIncome] }); // 保存订单总金额
              });

              const yMax1 = getNextBiggestNumber(
                Math.max.apply(
                  null,
                  res.map(item => item.chargedAmount)
                )
              );
              const yMax2 = getNextBiggestNumber(
                Math.max.apply(
                  null,
                  res.map(item => item.chargedPower)
                )
              );

              let series = [
                {
                  id: 0,
                  name: '电量(度)',
                  data: chargedPower,
                  hoverAnimation: false,
                  symbolSize: 6,
                  type: 'line',
                  yAxisIndex: 1,
                  itemStyle: {
                    emphasis: {
                      borderWidth: 3,
                      color: '#4D8CFD',
                    },
                    normal: {
                      color: '#4D8CFD',
                      lineStyle: {
                        color: '#4D8CFD',
                      },
                    },
                  },
                },
                {
                  id: 1,
                  name: '订单总金额(元)',
                  data: chargedAmount,
                  hoverAnimation: false,
                  symbolSize: 6,
                  type: 'line',
                  itemStyle: {
                    emphasis: {
                      borderWidth: 3,
                      color: '#F5A623',
                    },
                    normal: {
                      color: '#F5A623',
                      lineStyle: {
                        color: '#F5A623',
                      },
                    },
                  },
                },
                {
                  id: 2,
                  name: '引流抽成费用(元)',
                  data: commissionAmount,
                  hoverAnimation: false,
                  symbolSize: 6,
                  type: 'line',
                  itemStyle: {
                    emphasis: {
                      borderWidth: 3,
                      color: '#6DD400',
                    },
                    normal: {
                      color: '#6DD400',
                      lineStyle: {
                        color: '#6DD400',
                      },
                    },
                  },
                },
                {
                  id: 3,
                  name: '用户实付合计(元)',
                  data: chargedAmountAfterDiscount,
                  hoverAnimation: false,
                  symbolSize: 6,
                  type: 'line',
                  itemStyle: {
                    emphasis: {
                      borderWidth: 3,
                      color: '#FE797E',
                    },
                    normal: {
                      color: '#FE797E',
                      lineStyle: {
                        color: '#FE797E',
                      },
                    },
                  },
                },
                {
                  id: 4,
                  name: '商户实收(元)',
                  data: operatorActualIncome,
                  hoverAnimation: false,
                  symbolSize: 6,
                  type: 'line',
                  itemStyle: {
                    emphasis: {
                      borderWidth: 3,
                      color: '#F7B500',
                    },
                    normal: {
                      color: '#F7B500',
                      lineStyle: {
                        color: '#F7B500',
                      },
                    },
                  },
                },
                {
                  id: 5,
                  name: '平台实收(元)',
                  data: platformActualIncome,
                  hoverAnimation: false,
                  symbolSize: 6,
                  type: 'line',
                  itemStyle: {
                    emphasis: {
                      borderWidth: 3,
                      color: '#4D8CFD',
                    },
                    normal: {
                      color: '#4D8CFD',
                      lineStyle: {
                        color: '#4D8CFD',
                      },
                    },
                  },
                },
              ];

              if (series) {
                series = series.filter(item => {
                  return this.selectCheckbox.includes(item.id);
                });
              }

              this.chartOption1 = this.getChartOption(
                series,
                chargedPower.length,
                ['金额(元)', '电量(度)'],
                yMax1,
                yMax2
              );
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       *
       */
      getChartOption(seriesData, dataNum, yName, yMax1, yMax2) {
        // yMax1 第一条y轴的最大值   yMax2 第二条y轴的最大值
        const divisor = 5;
        const lcmVal = chartlcm(yMax1, yMax2); // 获取两条y轴的最大公约数
        const Ymaxval_interval = YmaxvalAndinterval(yMax1, yMax2, lcmVal, divisor); // 计算y轴最大值和间隔值

        const chartOption = {
          grid: {
            top: '50px',
            bottom: '20px',
            left: '20px',
            right: '30px',
            containLabel: true, // 是否显示刻度
          },
          tooltip: {
            trigger: 'axis',
            formatter: params => {
              // 显示样式
              // console.log('params+++', params);
              let html = `<div class="tbox"><div>${params[0].value[0]}</div>`;
              params.forEach(item => {
                html += `<div class="showBox">
                          <div style="display:flex;">
                              <div style="width: 6px;height: 6px;margin:8px 8px 0 0;background: ${item.color};border-radius: 5px;"></div>
                              <span style="flex:1;color:${item.color}">${item.seriesName}：</span>
                              <span style="float:right;">${item.value[1]}</span>
                          </div>
                      </div>`;
              });
              html += '</div>';
              return html;
            },
          },
          legend: {
            // 图例
            show: true,
            selectedMode: false, // 默认不开启图列数据切换，否则会影响自定义字段的tooltip显示
            top: '18px',
            right: '100px',
            itemWidth: 9,
            itemHeight: 9,
            icon: 'circle',
            textStyle: {
              color: '#4A4A4A', // 图例文字颜色
            },
          },
          // 滚动条
          dataZoom: {
            type: 'slider',
            brushSelect: false,
            // 区域缩放
            show: dataNum > 14, // 默认显示14条数据，如果大于14条，则出现区域缩放
            backgroundColor: '#F0F0F0',
            fillerColor: '#CACACA',
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            borderColor: '#F6F7F8',
            handleIcon: 'path://path://M100, 100m -75, 0a75,75 0 1,0 150,0a75,75 0 1,0 -150,0',
            textStyle: {
              color: 'transparent',
            },
            height: 6,
            handleSize: '65%',
            handleStyle: {
              color: '#CACACA',
              shadowColor: 'rgba(77, 140, 253, 0.5)',
              borderColor: '#CACACA',
            },
            bottom: '40px',
            start: 0,
            end: dataNum <= 14 ? 100 : Math.floor((14 / dataNum) * 100), // 控制一页显示多少条数据
          },
          xAxis: {
            type: 'category', // 类型
            nameLocation: 'end',
            show: true,
            boundaryGap: false,
            axisLine: {
              show: true, // 坐标轴轴线相关设置
              lineStyle: {
                color: '#9B9B9B',
              },
            },
            axisLabel: {
              margin: 14,
              show: true,
              interval: 0, // 使x轴文字显示全
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: '#4A4A4A',
              },
            },
          },
          yAxis: [
            {
              type: 'value',
              name: yName[0],
              nameTextStyle: {
                padding: [0, 50, 0, 0], // 上右下左与原位置距离
              },
              min: 0, // 指定最小值
              max: Ymaxval_interval.max1, // 最大值
              interval: Ymaxval_interval.interval1, // 每个刻度的间隔值
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#E2E0E0',
                },
              },
              axisLabel: {
                formatter(value) {
                  return value.toFixed(2);
                },
              },
            },
            {
              type: 'value',
              name: yName[1],
              nameTextStyle: {
                padding: [0, 0, 0, 50], // 上右下左与原位置距离
              },
              min: 0, // 指定最小值
              max: Ymaxval_interval.max2, // 最大值
              interval: Ymaxval_interval.interval2, // 每个刻度的间隔值
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#E2E0E0',
                },
              },
              axisLabel: {
                formatter(value) {
                  return value.toFixed(2);
                },
              },
            },
          ],
          series: seriesData,
        };
        return chartOption;
      },
    },
  };
</script>

<style lang="scss">
  .echarts-detail {
    margin-top: 12px;

    .cardview-box {
      .cardview-head {
        .detail-head.flex.cardview-head-box {
          margin: 11.5px 0;
        }
      }
    }

    .cardview-box {
      padding: 0 10px !important;
    }

    .cardview-body {
      padding: 0 10px;
    }

    .el-checkbox-button {
      margin: 0;
    }

    .ykc-checkbox-button .el-checkbox-button,
    .ykc-checkbox-button .el-checkbox-button__inner {
      height: auto !important;
    }

    .el-checkbox-button__inner {
      margin: 0;
      margin-left: 8px;
      padding: 8px 17px;
    }

    .el-tabs__nav {
      text-align: center;
    }
    box-shadow: none;
    .ykc-table .el-table {
      border-top: 0;
    }

    .ykc-tabs {
      margin-top: -20px;
      .tabs.el-tabs {
        margin-left: -10px;
      }
    }
    .el-tabs--border-card > .el-tabs__content {
      padding: 0;
    }
    .ykc-tabs .el-tabs--border-card > .el-tabs__header .el-tabs__item {
      color: #000000;
      font-weight: 400;
    }

    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #9b9b9b;
    }

    .echarts-line {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
      }
      .row:nth-child(1) {
        margin-bottom: 20px;
      }
      .charts-box {
        width: 100%;
        height: 338px;
        border: 1px solid #e2e0e0;
      }
    }
  }
</style>
